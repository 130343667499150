@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif;
}

xmp {
  background-color: #EDF2F7;
}

.taro-widget *, .taro-widget *::before, .taro-widget *::after {
  box-sizing: border-box;
}
